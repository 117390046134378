import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { uploadFetch } from '../../hooks/useFetch';

interface HelpDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const HelpDialog: React.FC<HelpDialogProps> = ({ isOpen, onClose }) => {
  const [formError, setFormError] = useState('');
  const [supportEmailInputValue, setSupportEmailInputValue] = useState('');
  const [supportTelephoneNumberInputValue, setSupportTelephoneNumberInputValue] = useState('');
  const [supportSummaryInputValue, setSupportSummaryInputValue] = useState('');
  const [supportContactAllowedInputValue, setSupportContactAllowedInputValue] = useState(false);
  const [sendToDev,setSendToDev] = useState(false);

  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false); // Kontaktformular-Dialog

  const handleSendSupportMail = () => {
    if (!supportEmailInputValue || !supportTelephoneNumberInputValue || !supportSummaryInputValue) {
      setFormError('Bitte füllen Sie alle Felder aus, bevor Sie fortfahren.');
      return;
    }
    setFormError('');

    uploadFetch(
      'system/support',
      true,
      {
        email: supportEmailInputValue,
        telephonenumber: supportTelephoneNumberInputValue,
        description: supportSummaryInputValue,
        allowSupportContact: supportContactAllowedInputValue,
      },
      () => {}
    );

    // Dialog schließen und Felder zurücksetzen
    setIsContactDialogOpen(false);
    onClose();
    clearSupportInputs();
  };

  const clearSupportInputs = () => {
    setSendToDev(false);
    setSupportContactAllowedInputValue(false);
    setSupportEmailInputValue('');
    setSupportTelephoneNumberInputValue('');
    setSupportSummaryInputValue('');
  };

  const openContactForm = (title: string) => {
    setSupportSummaryInputValue(title); // Setzt den Titel direkt als Problembeschreibung
    setIsContactDialogOpen(true);
  };


  return (
    <>
      {/* Haupt-Hilfe-Dialog */}
      <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogContent>Hilfe</DialogContent>
        <DialogContent>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="get_documents">
              <Typography>Mein Konto ist gesperrt. Wie kann es wieder freigeschaltet werden?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Ihr Konto wird gesperrt, wenn es mehrere fehlgeschlagene Anmeldeversuche gab.
                Aus Sicherheitsgründen kann Ihr Konto nur manuell von den Mitarbeitenden Ihres Notarbüros entsperrt werden.
                <br />
                Bitte wenden Sie sich hierfür direkt an den Support Ihres Notarbüros. Das Team wird Ihr Konto gern schnellstmöglich entsperren.<Link
                component="button"
                onClick={() => openContactForm('Konto gesperrt')}
                style={{ marginTop: '10px', display: 'inline-block' }}
              >
                Support kontaktieren
              </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="download_documents">
              <Typography>Ich habe keinen Authentifizierungsschlüssel erhalten. Was ist zu tun?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Um Ihr Konto optimal zu schützen, verwenden wir eine 2-Faktor-Authentifizierung. Dies bedeutet, dass Sie nach der Eingabe Ihrer E-Mail-Adresse und Ihres Passworts einen Authentifizierungsschlüssel per E-Mail erhalten, den Sie dann ebenfalls eingeben müssen. Es kann einige Minuten dauern, bis der 2FA-Schlüssel bei Ihnen eingeht. Bitte überprüfen Sie auch Ihren Spam-Ordner.
                <br />
                Sollte auch nach mehreren Minuten keine E-Mail bei Ihnen eingegangen sein, setzen Sie sich bitte direkt mit dem Support Ihres Notarbüros in Verbindung. Das Team wird Ihnen gerne dabei helfen, das Problem zu lösen.
                <br />
                <Link
                  component="button"
                  onClick={() => openContactForm('Ich erhalte keinen 2FA-Code')}
                  style={{ marginTop: '10px', display: 'inline-block' }}
                >
                  Support kontaktieren
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="get_draft">
              <Typography>Ich möchte mein Passwort zurücksetzen. Wie gehe ich vor? </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Wenn Sie Ihr Passwort ändern möchten, können Sie dies in Ihrem DiNo-Benutzerkonto unter „Mein Benutzerkonto“ tun. Geben Sie Ihr aktuelles Passwort ein und legen Sie anschließend ein neues fest.
                <br />
                Falls Sie Ihr Passwort vergessen haben, klicken Sie auf „Passwort zurücksetzen“ auf der Startseite, geben Ihre E-Mail-Adresse ein und folgen den Anweisungen in den E-Mails.
                <br />
                Sollte die Zurücksetzung nicht möglich sein, wenden Sie sich direkt an den Support Ihres Notarbüros. Das Team hilft Ihnen gerne weiter
                <br />
                <Link
                  component="button"
                  onClick={() => openContactForm('Passwort kann nicht zurückgesetzt werden')}
                  style={{ marginTop: '10px', display: 'inline-block' }}
                >
                  Support kontaktieren
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Okay</Button>
        </DialogActions>
      </Dialog>

      {/* Kontaktformular-Dialog */}
      <Dialog open={isContactDialogOpen} onClose={() => setIsContactDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Kontaktformular</DialogTitle>
        <DialogContent>
          {formError && <Alert severity="error">{formError}</Alert>}
          <Typography variant="body2" style={{ marginBottom: '16px' }}>
            Beschreiben Sie Ihr Anliegen im Kontaktformular und wählen Sie aus, ob Ihre Anfrage an den Softwarehersteller oder Ihr Notarbüro gesendet werden soll.
            <br />
            <br />
            Für Kontosperrungen, fehlende Authentifizierungsschlüssel oder Passwortzurücksetzungen wenden Sie sich bitte direkt an Ihr Notarbüro.
            <br />
            <br />
            Falls die vorgeschlagenen Lösungen nicht geholfen haben oder ein anderes Problem vorliegt, stimmen Sie der untenstehenden Kontaktaufnahme zu. Ihre Anfrage wird dann an LeXtorByte weitergeleitet und ein Mitarbeiter wird sich mit Ihnen in Verbindung setzen.
            <br />
          </Typography>
          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid item sm={6}>
              <TextField
                value={supportEmailInputValue}
                style={{ width: '100%' }}
                onChange={(v) => setSupportEmailInputValue(v.target.value)}
                placeholder="E-Mail"
                label="E-Mail"
                required
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                value={supportTelephoneNumberInputValue}
                style={{ width: '100%' }}
                onChange={(v) => setSupportTelephoneNumberInputValue(v.target.value)}
                placeholder="Telefonnummer"
                label="Telefonnummer"
                required
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                value={supportSummaryInputValue} // Benutzerdefinierte Beschreibung
                style={{ width: '100%' }}
                onChange={(v) => setSupportSummaryInputValue(v.target.value)}
                multiline
                minRows={3}
                maxRows={6}
                label="Problembeschreibung"
                placeholder="Ihre Problembeschreibung hier"
                required
              />
            </Grid>
            
            <Grid item sm={12}>
                <FormControlLabel
                  label={
                    <Typography component="span">
                      An den Softwarehersteller (LeXtorByte UG) senden.
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={sendToDev}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSendToDev(event.target.checked);
                        setSupportContactAllowedInputValue(false);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
            </Grid>

            <Grid item sm={12}>
              <Collapse in={sendToDev}>
                <Alert severity="info" icon={false}>
                  <FormControlLabel
                    label={
                      <Typography component="span">
                        Hiermit erkläre ich mich damit einverstanden, dass meine Daten von der Firma LeXtorByte UG (haftungsbeschränkt)
                        zum Zwecke des Kontakts und zur Hilfestellung verarbeitet werden dürfen.
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={supportContactAllowedInputValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          setSupportContactAllowedInputValue(event.target.checked)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                  />
                </Alert>
              </Collapse>
            </Grid>


          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsContactDialogOpen(false)}>Abbrechen</Button>
          <Button variant="contained" color="primary" disabled={sendToDev && !supportContactAllowedInputValue} onClick={handleSendSupportMail}>
            Abschicken
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
