import React, { useState } from 'react';
import { Alert, Grid, Typography } from '@mui/material';
import { LegalRequirementRow } from './LegalRequirementRow';
import { ILegalPhaseFullObject } from '../../../../Interfaces/ILegalPhase';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { LegalInfoText } from './LegalInfoText';
import { IConfig } from '../../../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
  legalTransaction: ILegalTransactionFullObject;
  legalPhaseFullObject: ILegalPhaseFullObject;
}

export const LegalPhaseRow: React.FC<IProps> = ({
  configObject,
  legalTransaction,
  legalPhaseFullObject,
}) => {
  const legalPhaseObject = legalPhaseFullObject;
  const [legalReqArray, setLegalReqArray] = useState(
    legalPhaseFullObject.LegalRequirementArray
  );

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Alert
          variant='standard'
          severity={legalPhaseObject.isComplete ? 'success' : 'info'}
          action={
            <>
              <LegalInfoText currentObject={legalPhaseObject} />
            </>
          }
        >
          {legalPhaseObject.LegalPhase}
          {legalPhaseObject.PreconditionArray.length !== 0 && (
            <>
              <br />
              <Typography variant='caption'>Vorgänger:</Typography>{' '}
            </>
          )}
          {
            <Typography variant='caption'>
              {legalPhaseObject.PreconditionArray.map(x => x.LegalPhase).join(
                ', '
              )}
            </Typography>
          }
        </Alert>
      </Grid>

      {legalReqArray
        .filter(legalReq => legalReq.isInterneLegalRequirement !== true)
        .map(legalRequirement => (
          <LegalRequirementRow
            key={`idLegalRequirement-${legalRequirement.idLegalRequirement}`}
            configObject={configObject}
            legalTransaction={legalTransaction}
            legalRequirement={legalRequirement}
            legalRequirementArray={legalReqArray}
            setLegalRequirementArray={setLegalReqArray}
            isNotDisabled
            startDeadlineDate={
              legalTransaction.StartEditDate ||
              legalTransaction.NotarizationDate ||
              null
            }
          />
        ))}
    </>
  );
};
