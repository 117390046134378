import React from 'react';
import { IDocument } from '../../../Interfaces/IDocument';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import { useCreateDocumentDownloaded, useDocuments } from '../../../hooks/data';
import { DocumentAreaRow } from './DocumentAreaRow';


export const DownloadArea: React.FC = () => {
  const { documents, isLoadingDocuments } = useDocuments();

  if (isLoadingDocuments) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!documents || documents.length === 0) {
    return (
      <Box sx={{ mt: 2, mb: 10 }}>
        <Typography variant='h4'>Meine Dokumente</Typography>
        <Alert severity='info'>
          Derzeit sind keine Dokumente verfügbar.
          <br />
          Sofern Sie Dokumente angefragt haben, werden diese hier aufgelistet
          und können heruntergeladen werden. Bitte haben Sie einen Moment
          Geduld, während die Bereitstellung der Dokumente geprüft wird. Dieser
          Vorgang kann bis zu fünf Minuten dauern.
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2, mb: 10 }}>
      <Typography variant='h4'>Meine Dokumente</Typography>
      <Alert severity='info' sx={{ mt: 2 }}>
        Um Mitternacht werden alle angefragten Dokumente von diesem Server
        gelöscht.
      </Alert>

      <Table size='small' sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Dateiname</TableCell>
            <TableCell sx={{ width: 20 }}>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map(document => (
            <DocumentAreaRow
              key={`idDocument-${document.idDocument}`}
              document={document}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
