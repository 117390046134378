import React from "react";
import { IDocument } from "../../../Interfaces/IDocument";
import { Box, Button, CircularProgress, IconButton, TableCell, TableRow, useMediaQuery } from "@mui/material";
import { useCreateDocumentDownloaded } from "../../../hooks/data";
import { Download } from "@mui/icons-material";

interface IProps {
    document: IDocument;
}
const base64Download = (localDocumentObject: IDocument) => {
    if (localDocumentObject.Data !== undefined) {
      const byteCharacters = atob(localDocumentObject.Data);
      // Each character's code point (charCode) will be the value of the byte.
      // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
      const byteArray = new Uint8Array(byteNumbers);
      // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      // Erstelle Link zum BLOB
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = localDocumentObject.FileName;
      a.click();
    }
  };

  

export const DocumentAreaRow:React.FC<IProps> = ({document}) => {
    const isDesktop = useMediaQuery('(min-width:600px)');
    const { createDocumentDownloaded, isPendingDocumentDownloaded } = useCreateDocumentDownloaded();

    const onDownload = async (currentDocument: IDocument) => {
      await createDocumentDownloaded(currentDocument);
      base64Download(currentDocument);
    }

    
    return(
        <TableRow
            sx={{ cursor: 'pointer' }}
            onClick={() => onDownload(document)}
        >
            <TableCell>{document.FileName}</TableCell>
            <TableCell>
            {isDesktop ? (
                <Button variant='contained' disabled={isPendingDocumentDownloaded}>
                {isPendingDocumentDownloaded ? (
                    <Box display='flex' alignItems='center' gap={1}>
                    <span>Download</span>
                    <CircularProgress size={20} />
                    </Box>
                ) : (
                    'Download'
                )}
                </Button>
            ) : (
                <IconButton disabled={isPendingDocumentDownloaded}>
                {isPendingDocumentDownloaded
                    ? <CircularProgress size={20} /> 
                    : <Download />
                }
                </IconButton>
            )}
            </TableCell>
        </TableRow>
    )
}