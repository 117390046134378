import React, { useState } from 'react';
import { ILegalTransactionFullObject } from '../../../Interfaces/ILegalTransaction';
import { IDocument } from '../../../Interfaces/IDocument';
import { IConfig } from '../../../Interfaces/IConfig';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Typography,
} from '@mui/material';
import { LegalRequirementRequestFiles } from '../LegalTransaction/LegalPhase/LegalRequirementRequestFiles';
import { Description } from '@mui/icons-material';

interface IProps {
  configObject: IConfig;
  legalTransactionObject: ILegalTransactionFullObject;
  documentArray: IDocument[];
  title: string;
  handleClose?: () => void;
}

export const DocumentOverviewMobile: React.FC<IProps> = ({
  configObject,
  legalTransactionObject,
  documentArray,
  title,
}) => {
  const [isOpenRequestFile, setIsOpenRequestFile] = useState(false);
  const [idDocument, setIdDocument] = useState(1);

  const handleClick = (id: number) => {
    setIdDocument(id);
    setIsOpenRequestFile(true);
  };

  return (
    <>
      <Dialog
        open={isOpenRequestFile}
        onClose={() => setIsOpenRequestFile(false)}
        maxWidth='lg'
        fullWidth
      >
        <LegalRequirementRequestFiles
          configObject={configObject}
          idDocument={idDocument}
          legalTransaction={legalTransactionObject}
          documentArray={documentArray.filter(x => x.idDocument > 0)}
          setIsOpen={setIsOpenRequestFile}
        />
      </Dialog>

      <Typography variant='h5'>{title}</Typography>
      {documentArray.map(x => (
        <Box sx={{ mt: 2 }}>
          <Card
            variant='outlined'
            sx={{ cursor: 'pointer' }}
            onClick={() => handleClick(x.idDocument)}
          >
            <CardContent>
              <Description sx={{ float: 'right' }} fontSize='large' />
              <Box sx={{ ml: 2, mt: 1 }}>
                {x.FileName.length <= 20
                  ? x.FileName
                  : `${x.FileName.substring(0, 19)}...`}
              </Box>
              <Box sx={{ ml: 2, mb: -1 }}>
                <Typography variant='caption'>
                  Vom {x.Created_at ? x.Created_at : <>k.A.</>}
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <Button>Anfragen</Button>
            </CardActions>
          </Card>
        </Box>
      ))}
    </>
  );
};
