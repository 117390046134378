import React, { useState } from 'react';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { LegalPhaseRow } from './LegalPhaseRow';
import { ILegalPhaseFullObject } from '../../../../Interfaces/ILegalPhase';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { IConfig } from '../../../../Interfaces/IConfig';
import { LegalPhaseDocumentView } from './LegalPhaseDocumentView';

interface IProps {
  configObject: IConfig;
  legalTransaction: ILegalTransactionFullObject;
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
}

type View = 'phase_view' | 'document_view';

export const LegalPhaseMain: React.FC<IProps> = ({
  configObject,
  legalTransaction,
  legalPhaseFullObjectArray,
}) => {
  const [currentLegalPhaseFullObjects, setCurrentLegalPhaseFullObjects] =
    useState<ILegalPhaseFullObject[]>([]);
  const [currentView, setCurrentView] = useState<View>('phase_view');

  /*useEffect(() => {
    /// map: LegalPhase aufbereiten, falls Postion nicht vorhanden
    setCurrentLegalPhaseFullObjects([
      ...currentLegalPhaseFullObjects.map((currentLegalPhase, idx) =>
        currentLegalPhase.Position !== null
          ? currentLegalPhase
          : { ...currentLegalPhase, Position: idx + 1 }
      ),
    ]);
    /// end: map
  }, [currentLegalPhaseFullObjects, legalPhaseFullObjectArray]);*/
  // durch das useEffect ensteht ein loop. Zumal CurrentLegalPhaseFullObject auch für nichts verwendet wird?!

  return (
    <>
      <Typography variant='h5'>Aktueller Statusverlauf</Typography>

      <Box sx={{ float: 'right', mb: 3 }}>
        Fragen zum Statusverlauf? Halten Sie Ihre Maus oder Finger über den
        Fortschrittsbalken für mehr Informationen oder schreiben Sie uns eine{' '}
        <a
          href={`mailto:${configObject.email}?subject=[${
            !legalTransaction.TransactionNumberArray ||
            legalTransaction.TransactionNumberArray.length === 0
              ? legalTransaction.Title
              : legalTransaction.TransactionNumberArray[0]
          }]: Statusverlauf`}
        >
          E-Mail
        </a>
        !
      </Box>
      <Box sx={{ mt: 2, mb: 3 }}>
        <TextField
          label='Ansicht'
          value={currentView}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCurrentView(e.target.value as View)
          }
          size='small'
          select
        >
          <MenuItem key='phase-view-normal' value='phase_view'>
            Vollständiger Statusverlauf
          </MenuItem>
          <MenuItem key='phase-view-document' value='document_view'>
            Dokumentenansicht
          </MenuItem>
        </TextField>
      </Box>

      {currentView === 'phase_view' ? (
        <Grid container spacing={1}>
          {legalPhaseFullObjectArray
            .sort((a, b) => (Number(a.Position) < Number(b.Position) ? -1 : 1))
            .map(legalPhaseFullObject => (
              <LegalPhaseRow
                key={`idLegalPhase-${legalPhaseFullObject.idLegalPhase}`}
                configObject={configObject}
                legalTransaction={legalTransaction}
                legalPhaseFullObject={legalPhaseFullObject}
              />
            ))}
        </Grid>
      ) : (
        <LegalPhaseDocumentView
          configObject={configObject}
          legalPhaseFullObjectArray={legalPhaseFullObjectArray}
          legalTransaction={legalTransaction}
        />
      )}
    </>
  );
};
